import { React } from "react";
import { Grid } from "@material-ui/core";
import { useShopify } from "../hooks";
import Product from "./Product";
import Footer from "./Footer";
import NewNav from "./NewNav";

const Placeholder = () => {
  const getNumberOfItems = () => {
    const width = window.innerWidth;

    if (width < 960) return 4;
    if (width < 1280) return 6;
    return 8;
  };

  const numberedArray = Array.from(Array(getNumberOfItems()).keys());

  return numberedArray.map((number) => (
    <Grid item xs={6} md={4} lg={3} key={number}>
      <div className="loading_block" />
    </Grid>
  ));
};

const Home = (props) => {
  const { products } = useShopify();

  return (
    <div id="container">
      <NewNav />

      {/* <div className="App__header">
        <div className="carousel-image">
          <img src="././Assets/banner.png" alt="TerasHD Merch Store" />
        </div>
      </div> */}

      <div className="App__title">
        <h1 className="App__title-text">
          The Official TerasHD Clothing Store
        </h1>
      </div>

      <div className="Products-wrapper">
        <div className="Products-wrapper__inner">
          <Grid container spacing={3}>
            {products && products.length > 0 ? (
              products.map((product, i) => (
                <Grid item xs={6} md={4} lg={3} key={i}>
                  <Product product={product} history={props.history} />
                </Grid>
              ))
            ) : (
              <Placeholder />
            )}
          </Grid>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
